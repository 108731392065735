import React, { useEffect } from "react";
import { motion } from "framer-motion";
import AOS from "aos";
import "aos/dist/aos.css";

const VideoArea = () => {
	useEffect(() => {
		AOS.init({ duration: 1200, easing: "ease-in-out", once: true });
	}, []);

	return (
		<section className="video-area vdeo-bg">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						{/* Video Icon */}
						<motion.div
							className="video-icon-area relative flex lg:items-start lg:justify-start"
							initial={{ opacity: 0, scale: 0.7 }}
							animate={{ opacity: 1, scale: 1 }}
							transition={{ duration: 1.2, ease: "easeInOut" }}
						>
							<a
								href="https://youtu.be/tHEkrxPnFOM"
								target="_blank"
								rel="noopener noreferrer"
								className="flex items-center justify-center w-40 h-40 rounded-full bg-gradient-to-r 
                                from-indigo-500 to-rose-400 shadow-xl transition-transform transform hover:scale-110
                                hover:from-rose-500 hover:to-indigo-500"
								data-aos="zoom-in"
							>
								<i className="fa fa-play text-white text-5xl"></i>
							</a>
						</motion.div>
					</div>
				</div>
			</div>

			{/* Animated Content */}
			<motion.div
				className="video-content lg:rounded-3xl p-8 lg:p-16 flex flex-col items-center md:items-start justify-center md:justify-start text-center"
				initial={{ opacity: 0, y: 30 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 1, delay: 0.3 }}
			>
				{/* Background Blur Effect */}
				<div
					className="absolute -z-10 inset-0 bg-gradient-to-br from-indigo-500/[0.05] via-transparent to-rose-500/[0.05] blur-3xl"
					data-aos="fade-up"
				></div>

				{/* Background Text Animation */}
				<div className="absolute inset-0 flex items-center justify-center overflow-hidden pointer-events-none">
					<motion.span
						initial={{ opacity: 0, scale: 0.5 }}
						animate={{ opacity: 0.09, scale: 1 }}
						transition={{ duration: 1.5, ease: "easeOut" }}
						className="text-[10vw] text-white font-pacifico whitespace-nowrap"
					>
						Videos
					</motion.span>
				</div>

				{/* Heading with Animation */}
				<h1
					className="text-4xl md:text-left z-20 md:text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 via-white/90 to-rose-400 mb-4"
					data-aos="fade-up"
					data-aos-delay="300"
				>
					Watch Our Latest Videos
				</h1>

				<p
					className="text-lg md:text-left text-white/60 max-w-xl mx-auto mb-6 cursor-default z-20"
					data-aos="fade-up"
					data-aos-delay="500"
				>
					Explore our mission, values, and vision in action. Discover how we can
					help your business grow.
				</p>

				{/* Call to Action Button */}
				<motion.a
					href="https://wa.me/2347069333829"
					className="inline-flex items-center justify-center px-6 py-3 text-lg font-semibold text-white bg-gradient-to-r from-indigo-500 to-rose-400 rounded-full shadow-lg transition-all duration-300 ease-in-out hover:from-rose-500 hover:to-indigo-500"
					whileHover={{ scale: 1.05 }}
					whileTap={{ scale: 0.95 }}
					data-aos="fade-up"
					data-aos-delay="700"
				>
					Learn More
				</motion.a>
			</motion.div>
		</section>
	);
};

export default VideoArea;
