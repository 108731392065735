import React, { useEffect } from "react";
import { motion } from "framer-motion";
import PortfolioItem from "./PortfolioItem";
import HeroGeometric from "./HeroGeometric"; // Import your HeroGeometric component
import AOS from 'aos';
import 'aos/dist/aos.css';

const PortfolioArea = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-out-quart',
      once: true,
    });
  }, []);

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <section id="portfolio">
      {/* Hero Section (Light Theme Version) */}
      <HeroGeometric
        badge="Our Work"
        title1="Projects We're"
        title2="Proud Of"
        lightTheme={true} // Added a prop to control the theme
      />

      <div className="bg-gray-50 py-24"> {/* Light background */}
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <PortfolioItem
              image="/assets/images/portfolio/01.png"
              title="SMARTEDU"
              description="Smart education platform revolutionizing learning."
              data-aos="fade-up"
              data-aos-delay="300"
            />
            <PortfolioItem
              image="/assets/images/portfolio/tt.png"
              title="Taron"
              description="Event app enhancing user experience and engagement."
              data-aos="fade-up"
              data-aos-delay="400"
            />
            <PortfolioItem
              image="/assets/images/portfolio/q.png"
              title="Qevla"
              description="Towing Company streamlined operations and customer service."
              data-aos="fade-up"
              data-aos-delay="500"
            />
            <PortfolioItem
              image="/assets/images/portfolio/2.jpg"
              title="CIB Development Consulting"
              description="Corporate Internet Banking solutions for secure transactions."
              data-aos="fade-up"
              data-aos-delay="600"
            />
            <PortfolioItem
              image="/assets/images/portfolio/p.jpg"
              title="EaziPAY"
              description="Payment Integration system for seamless checkout processes."
              data-aos="fade-up"
              data-aos-delay="700"
            />
            <PortfolioItem
              image="/logo.png"
              title="Future Project"
              description="Your next big idea – let's bring it to life together."
              data-aos="fade-up"
              data-aos-delay="800"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PortfolioArea;