import React from "react";
import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import { BackgroundShapes } from "../revamp/statistics/BackgroundShapes";

const AboutUsArea = () => {
	const controls = useAnimation();
	const [ref, inView] = useInView({
		triggerOnce: true,
		threshold: 0.1,
	});

	useEffect(() => {
		if (inView) {
			controls.start("visible");
		}
	}, [controls, inView]);

	const containerVariants = {
		hidden: { opacity: 0 },
		visible: {
			opacity: 1,
			transition: {
				staggerChildren: 0.2,
				delayChildren: 0.3,
			},
		},
	};

	const titleVariants = {
		hidden: { opacity: 0, y: -50 },
		visible: {
			opacity: 1,
			y: 0,
			transition: {
				duration: 0.8,
				ease: [0.17, 0.67, 0.83, 0.67],
			},
		},
	};
	return (
		<section className="about-us-area relative" id="About">
			<div className="absolute inset-0 bg-gradient-to-br from-indigo-50 via-white to-rose-50" />

			<BackgroundShapes />
			<div className="container">
				<div className="row">
					<div className="about-us-img">
						<img
							src="assets/images/about-us/1.png"
							alt=""
							className="about-img-1"
						/>
						<img
							src="assets/images/about-us/2.png"
							alt=""
							className="about-img-2"
						/>
					</div>
					<div className="col-lg-5 offset-lg-7">
						<div className="about-us-content">
							<div className="section-title about-us-title">
								<motion.div
									ref={ref}
									variants={containerVariants}
									initial="hidden"
									animate={controls}
									className="inline-flex items-center justify-center gap-2 px-3 py-2 rounded-full bg-gradient-to-r from-indigo-500/10 to-rose-500/10 backdrop-blur-sm mb-8"
								>
									<img src="/logo.png" alt="Easynet" className="w-8 h-8" />
									<motion.h2
										variants={titleVariants}
										className="text-xl font-medium text-gray-800 items-center justify-center"
									>
										About Us
									</motion.h2>
								</motion.div>
								<motion.div
									ref={ref}
									variants={containerVariants}
									initial="hidden"
									animate={controls}
									className="text-left mb-16"
								>
									<motion.h1
										variants={titleVariants}
										className="text-5xl md:text-6xl font-bold text-gray-800 leading-tight mb-8"
									>
										Your Most{" "}
										<span className="font-pacifico p-4 bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 to-rose-500">
											Trusted Partner
										</span>{" "}
										For Digital Transformationt
									</motion.h1>
								</motion.div>
							</div>
							<div className="about-us-text">
								<span>E</span>
								<motion.p
									variants={titleVariants}
									className=""
								>
									EASYNET TELSURVE CO is a leading digital transformation
									company providing tailored, cutting-edge solutions for
									individuals, businesses, and corporations. Their services
									include digital strategy consulting, software development, IT
									infrastructure management, digital marketing, and
									cybersecurity, all designed to help clients succeed in the
									evolving digital world.
								</motion.p>
							</div>
							<ul className="about-menu">
								<li>
									<a href="#">
										<i className="fas fa-check"></i>Web Design
									</a>
								</li>
								<li>
									<a href="#">
										<i className="fas fa-check"></i>Web Development
									</a>
								</li>
								<li>
									<a href="#">
										<i className="fas fa-check"></i>Microservices
									</a>
								</li>
								<li>
									<a href="#">
										<i className="fas fa-check"></i>Startups Consulting
									</a>
								</li>
								<li>
									<a href="#">
										<i className="fas fa-check"></i>Infrastructure management
									</a>
								</li>
							</ul>
							<motion.div variants={titleVariants} className="text-left">
								<motion.a
									href="https://wa.me/2347069333829"
									target="_blank"
									rel="noopener noreferrer"
									className="inline-flex items-center px-8 py-4 text-lg font-semibold text-white bg-gradient-to-r from-indigo-600 to-rose-500 rounded-full shadow-lg transition-all duration-300 hover:from-rose-500 hover:to-indigo-600 hover:scale-105"
									whileHover={{ scale: 1.05 }}
									whileTap={{ scale: 0.95 }}
								>
									ContactUs
								</motion.a>
							</motion.div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AboutUsArea;
