"use client";

import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { motion } from "framer-motion";
import { BackgroundShapes } from "../revamp/statistics/BackgroundShapes";
import { Code, Brain, Terminal } from "lucide-react";
import { cn } from "../utils";
import HeroGeometric from "../revamp/portfolio/HeroGeometric";

const ServiceOffer = () => {
	useEffect(() => {
		AOS.init({
			duration: 1000,
			once: true,
			easing: "ease-in-out",
		});
	}, []);

	const serviceVariants = {
		hidden: { opacity: 0, y: 50 },
		visible: {
			opacity: 1,
			y: 0,
			transition: {
				duration: 0.8,
				ease: "easeInOut",
			},
		},
	};

	const ServiceCard = ({ number, Icon, title, description, delay }) => {
		const [isHovered, setIsHovered] = useState(false);
		return (
			<motion.div
				className="w-full sm:w-1/2 lg:w-1/3 p-4"
				variants={serviceVariants}
				initial="hidden"
				whileInView="visible"
				viewport={{ once: true }}
				data-aos="fade-up"
				data-aos-delay={delay}
			>
				<div
					className={`
                        bg-white/10 backdrop-blur-lg rounded-3xl shadow-lg 
                        transition-all duration-300 h-full
                        border border-white/20 p-6
                        ${isHovered ? "scale-105 shadow-xl" : ""}
                    `}
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}
				>
					<div className="flex justify-between items-center mb-4">
						<span className="text-4xl font-bold text-indigo-600 opacity-50">
							{number}
						</span>
						<Icon size={40} className="text-indigo-600" />
					</div>
					<h4
						className={cn(
							"p-3 text-3xl bg-clip-text text-transparent bg-gradient-to-r from-indigo-500  to-rose-400",
							"font-pacifico"
						)}
					>
						{title}
					</h4>
					<p className="text-gray-600">{description}</p>
				</div>
			</motion.div>
		);
	};

	return (
		<section className="relative">
			<div className="absolute inset-0 bg-gradient-to-br from-indigo-50 via-white to-rose-50" />
			<BackgroundShapes />
                 <HeroGeometric
                    badge="Our Services"
                    title1="Servies"
                    title2="For You"
                    lightTheme={true} // Added a prop to control the theme
                  />
			<div className="container mx-auto px-4 relative z-10">
				<div className="flex flex-wrap -mx-4">
					<ServiceCard
						number="01"
						Icon={Code}
						title="Microservices Architecture"
						description="Optimize digital infrastructure & unlock tailored software solutions for growth."
						delay="0"
					/>
					<ServiceCard
						number="02"
						Icon={Brain}
						title="AI Engineering & Modeling"
						description="Gain a competitive edge with AI, achieving higher ROI in your business."
						delay="200"
					/>
					<ServiceCard
						number="03"
						Icon={Terminal}
						title="DevOps Strategies"
						description="Enhance efficiency & collaboration with cutting-edge DevOps."
						delay="400"
					/>
				</div>
			</div>
		</section>
	);
};

export default ServiceOffer;
