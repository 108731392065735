import { motion } from "framer-motion";

const Shape = ({ className, delay, duration, rotate, zIndex }) => (
	<motion.div
		className={`absolute rounded-full mix-blend-multiply filter blur-3xl opacity-80 ${className}`}
		initial={{ scale: 0, opacity: 0, rotateX: rotate.x, rotateY: rotate.y }}
		animate={{
			scale: 1,
			opacity: 0.7,
			rotateX: [rotate.x, rotate.x + 15, rotate.x],
			rotateY: [rotate.y, rotate.y - 15, rotate.y],
			y: [0, -10, 0], // Floating effect
		}}
		transition={{
			delay,
			duration,
			ease: "easeInOut",
			repeat: Infinity,
			repeatType: "mirror",
		}}
		style={{ zIndex, transformStyle: "preserve-3d", perspective: 1000 }}
	/>
);

export const BackgroundShapes = () => (
	<>
		<Shape
			className="bg-indigo-400 w-80 h-80 -top-10 -left-10 shadow-2xl"
			delay={0.2}
			duration={3}
			rotate={{ x: 20, y: -10 }}
			zIndex={5}
		/>
		<Shape
			className="bg-rose-400 w-96 h-96 top-1/4 -right-20 shadow-xl"
			delay={0.4}
			duration={3.2}
			rotate={{ x: -15, y: 15 }}
			zIndex={4}
		/>
		<Shape
			className="bg-yellow-300 w-72 h-72 bottom-1/4 left-1/4 shadow-lg"
			delay={0.6}
			duration={3.5}
			rotate={{ x: 25, y: -5 }}
			zIndex={3}
		/>
		<Shape
			className="bg-teal-300 w-64 h-64 -bottom-10 right-1/3 shadow-lg"
			delay={0.8}
			duration={3.8}
			rotate={{ x: -10, y: 20 }}
			zIndex={2}
		/>
		<Shape
			className="bg-purple-400 w-56 h-56 top-1/3 left-1/3 shadow-md"
			delay={1}
			duration={4}
			rotate={{ x: 15, y: -15 }}
			zIndex={1}
		/>
	</>
);
