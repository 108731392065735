import React, { useEffect } from "react";
import { motion } from "framer-motion";
import "@fontsource/pacifico";
import { cn } from "../utils"; // Assuming you have a utility function for class names
import AOS from "aos";
import "aos/dist/aos.css";
// Import ElegantShape component (if it's in a separate file)
function ElegantShape({
	className,
	delay = 0,
	width = 400,
	height = 100,
	rotate = 0,
	gradient = "from-white/[0.08]",
}) {
	return (
		<motion.div
			initial={{
				opacity: 0,
				y: -150,
				rotate: rotate - 15,
			}}
			animate={{
				opacity: 1,
				y: 0,
				rotate: rotate,
			}}
			transition={{
				duration: 2.4,
				delay,
				ease: [0.23, 0.86, 0.39, 0.96],
				opacity: { duration: 1.2 },
			}}
			className={cn("absolute", className)}
		>
			<motion.div
				animate={{
					y: [0, 15, 0],
				}}
				transition={{
					duration: 12,
					repeat: Infinity,
					ease: "easeInOut",
				}}
				style={{
					width,
					height,
				}}
				className="relative"
			>
				<div
					className={cn(
						"absolute inset-0 rounded-full",
						"bg-gradient-to-r to-transparent",
						gradient,
						"backdrop-blur-[2px] border-2 border-white/[0.15]",
						"shadow-[0_8px_32px_0_rgba(255,255,255,0.1)]",
						"after:absolute after:inset-0 after:rounded-full",
						"after:bg-[radial-gradient(circle_at_50%_50%,rgba(255,255,255,0.2),transparent_70%)]"
					)}
				/>
			</motion.div>
		</motion.div>
	);
}
const ServiceArea = () => {
	useEffect(() => {
		AOS.init({
			duration: 1200,
			easing: "ease-in-out",
			once: true,
			delay: 200,
		});
	}, []);

	const fadeInUp = {
		hidden: { opacity: 0, y: 50 },
		visible: {
			opacity: 1,
			y: 0,
			transition: {
				duration: 0.7,
				ease: "easeInOut",
			},
		},
	};

	const cardVariants = {
		initial: { opacity: 0, scale: 0.95 },
		animate: { opacity: 1, scale: 1 },
		whileHover: { scale: 1.03, boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)" },
	};

	return (
		<section
			className="relative min-h-screen py-24 overflow-hidden bg-[#030303]"
			id="Services"
		>
			{/* Background Gradient */}
			<div className="absolute inset-0 bg-gradient-to-br from-indigo-500/[0.05] via-transparent to-rose-500/[0.05] blur-3xl" />

			{/* Elegant Shapes */}
			<div className="absolute inset-0 overflow-hidden">
				<ElegantShape
					delay={0.3}
					width={400}
					height={100}
					rotate={12}
					gradient="from-indigo-500/[0.15]"
					className="left-[-10%] top-[10%]"
					data-aos="fade-right"
				/>

				<ElegantShape
					delay={0.5}
					width={300}
					height={80}
					rotate={-15}
					gradient="from-rose-500/[0.15]"
					className="right-[-5%] top-[60%]"
					data-aos="fade-left"
				/>

				<ElegantShape
					delay={0.4}
					width={200}
					height={60}
					rotate={-8}
					gradient="from-violet-500/[0.15]"
					className="left-[5%] bottom-[15%]"
					data-aos="fade-up"
				/>

				<ElegantShape
					delay={0.6}
					width={150}
					height={40}
					rotate={20}
					gradient="from-amber-500/[0.15]"
					className="right-[15%] top-[25%]"
					data-aos="fade-down"
				/>
			</div>

			<div className="container mx-auto px-4 relative z-10">
				{/* Section Title */}
				<div className="mb-20 text-center bg-black/50 p-8 rounded-3xl shadow-lg">
					<motion.div
						className="inline-flex items-center gap-2 px-3 py-2 rounded-full bg-white/[0.08] mb-8 md:mb-12"
						initial="hidden"
						animate="visible"
						variants={fadeInUp}
						data-aos="fade-up"
						data-aos-delay="100"
					>
						<img src="/logo_dark.png" alt="Easynet" className="w-4 h-4" />
						<span className="text-sm text-white tracking-wide">
							Our Expertise
						</span>
					</motion.div>
					<motion.div
						initial="hidden"
						animate="visible"
						variants={fadeInUp}
						data-aos="fade-up"
						data-aos-delay="200"
					>
						<h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6 md:mb-8 tracking-tight">
							<span className="bg-clip-text text-transparent bg-gradient-to-b from-white to-white/80">
								Core Services
							</span>
							<br />
							<span
								className={cn(
									"p-4 bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 via-white/90 to-rose-400",
									"font-pacifico"
								)}
							>
								Designed for You
							</span>
						</h1>
					</motion.div>
					<motion.div
						custom={2}
						variants={fadeInUp}
						initial="hidden"
						animate="visible"
						data-aos="fade-up"
						data-aos-delay="300"
					>
						<p className="text-base sm:text-lg md:text-xl text-white/80 mb-8 leading-relaxed font-light tracking-wide max-w-xl mx-auto px-4">
							We offer a comprehensive suite of services designed to help you
							achieve your business goals.
						</p>
					</motion.div>
				</div>

				{/* Service Cards */}
				<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
					<ServiceCard
						number="01"
						icon="fas fa-bezier-curve"
						title="Web Design & Development"
						description="Elevate your online presence with stunning and user-friendly web designs. Unlock the full potential of your online platform with dynamic web development solutions."
						data-aos="fade-up"
						data-aos-delay="200"
					/>
					<ServiceCard
						number="02"
						icon="fas fa-laptop-code"
						title="Artificial Intelligence Training and Modelling"
						description="Unlock the full potential of your online platform with our dynamic expertise in Machine Learning to model machines for any industry."
						data-aos="fade-up"
						data-aos-delay="300"
					/>
					<ServiceCard
						number="03"
						icon="fas fa-microchip"
						title="Microservices"
						description="Streamline your operations and scale your business efficiently with our specialized microservices architecture for seamless integration and flexibility."
						data-aos="fade-up"
						data-aos-delay="400"
					/>
					<ServiceCard
						number="04"
						icon="fas fa-briefcase"
						title="Startup Consulting"
						description="Receive valuable insights and strategic guidance for your startup's growth and success from our experienced consultants."
						data-aos="fade-up"
						data-aos-delay="500"
					/>
					<ServiceCard
						number="05"
						icon="fas fa-cogs"
						title="Infrastructure Management"
						description="Ensure the smooth functioning of your digital infrastructure with our comprehensive management services that optimize performance and security."
						data-aos="fade-up"
						data-aos-delay="600"
					/>
				</div>
			</div>

			{/* Bottom Gradient */}
			<div className="absolute inset-0 bg-gradient-to-t from-[#030303] via-transparent to-[#030303]/80 pointer-events-none" />
		</section>
	);
};

const ServiceCard = ({ number, icon, title, description, dataAos }) => {
	const cardVariants = {
		initial: { opacity: 0, y: 50 },
		animate: {
			opacity: 1,
			y: 0,
			transition: {
				duration: 0.6,
				ease: "easeInOut",
			},
		},
		whileHover: {
			scale: 1.05,
			boxShadow: "0 8px 24px rgba(0, 0, 0, 0.3)", // Darker shadow for better visibility
			transition: { duration: 0.2 },
		},
	};

	return (
		<motion.div
			className="bg-white/[0.05] backdrop-blur-md rounded-2xl shadow-md p-6 flex space-x-6 items-start transition-transform duration-300 hover:shadow-lg border border-white/[0.1]"
			variants={cardVariants}
			initial="initial"
			animate="animate"
			whileHover="whileHover"
			data-aos={dataAos}
		>
			<div className="flex-shrink-0">
				<div className="relative rounded-full h-12 w-12 flex items-center justify-center bg-rose-500 text-white font-semibold text-lg">
					{number}
					{/* Subtle glow effect */}
					<motion.div
						className="absolute inset-0 rounded-full bg-rose-500 opacity-30 blur-sm"
						style={{ zIndex: -1 }}
						animate={{ opacity: [0.3, 0.8, 0.3] }}
						transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
					/>
				</div>
			</div>

			<div className="flex flex-col flex-1 justify-between h-full">
				<div>
					<h3
						className="text-xl font-semibold text-white mb-2"
						style={{ fontFamily: "Pacifico" }}
					>
						<i className={`${icon} mr-2`}></i> {title}
					</h3>
					<p className="text-gray-400" style={{ fontFamily: "Bage" }}>
						{description}
					</p>
				</div>

				<a
					href="#"
					className="inline-flex items-center text-rose-500 hover:text-rose-700 font-semibold mt-4"
				>
					Read More
					<svg
						className="w-4 h-4 ml-1"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M9 5l7 7-7 7"
						/>
					</svg>
				</a>
			</div>
		</motion.div>
	);
};

export default ServiceArea;
