// App.js

import React from "react";
// import './App.css';
import Header from "./components/Header";
import Breadcrumb from "./components/Breadcrumb";
import Blog from "./components/Blog";
import ContactWithFooter from "./components/ContactWithFooter";
// import ScrollToTop from "./components/ScrollToTop";
import Loading from "./components/Loading";
import ServiceOffer from "./components/ServiceArea";
// import ServiceArea from "./components/Service";

import OffCanvasSearchArea from "./components/Search";
import AboutUsArea from "./components/AboutUsArea";
// import PortfolioArea from "./components/Portfolio";
import CtaArea from "./components/CTA";
import TeamArea from "./components/Team";
import VideoArea from "./components/Video";
// import ProjectCounterArea from "./components/ProjectCounter";
import PricingArea from "./components/PricingModel";
import ModernContactFooter from "./revamp/modern-contact-footer";
import ScrollToTop from "./revamp/scroll-to-top";
import ProjectCounterArea from "./revamp/statistics/project-counter-area";
import PortfolioArea from "./revamp/portfolio/PortfolioArea";
import ServiceArea from "./revamp/service";

function App() {
	return (
		<div className="App overflow-x-clip">
			{/* <Loading />  */}
			<OffCanvasSearchArea />
			<Header />
			<Breadcrumb />
			<ServiceOffer />
			<AboutUsArea />
			<ServiceArea />
			<PortfolioArea />
			<CtaArea />
			{/* <TeamArea /> */}
			<VideoArea />
			<ProjectCounterArea />
			{/* <PricingArea /> */}

			{/* <Blog /> */}
			{/* <ContactWithFooter /> */}
			<ModernContactFooter />
			<ScrollToTop />
		</div>
	);
}

export default App;
