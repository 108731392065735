import React from "react";
import { motion } from "framer-motion";

const PortfolioItem = ({ image, title, description, dataAos }) => {
  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.7,
        ease: "easeInOut",
      },
    },
    hover: {
      scale: 1.05,
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
  };

  const glowVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: [0, 0.5, 0],
      transition: {
        duration: 2,
        repeat: Infinity,
        ease: "linear",
      },
    },
  };

  return (
    <motion.div
      className="relative rounded-2xl overflow-hidden shadow-lg group"
      variants={itemVariants}
      initial="hidden"
      animate="visible"
      whileHover="hover"
      data-aos={dataAos}
    >
      {/* Glowing Background Effect */}
      {/* <motion.div
        className="absolute inset-0 bg-gradient-to-br from-rose-400 to-indigo-600 opacity-20 blur-lg"
        variants={glowVariants}
        initial="initial"
        animate="animate"
      /> */}

      {/* Image with Overlay */}
      <div className="relative flex items-center justify-center">
        <img
          src={image || "/logo.png"}
          alt={title}
          className="w-auto h-64 object-cover transition-transform duration-300 group-hover:scale-95"
        />
        {/* <div className="absolute inset-0 bg-black/40 group-hover:bg-black/60 transition-colors duration-300"></div> */}
      </div>

      {/* Content Area */}
      <div className="p-6 relative z-10">
        <h3
          className="text-xl font-semibold text-black mb-2"
          style={{ fontFamily: "Pacifico" }}
        >
          {title}
        </h3>
        <p
          className="text-sm text-gray-600"
          style={{ fontFamily: "Bage" }}
        >
          {description}
        </p>
      </div>


      {/* Holographic Effect (Subtle) */}
      <motion.div
        className="absolute inset-0 pointer-events-none"
        style={{
          background: "linear-gradient(90deg, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0) 100%)",
          mixBlendMode: "overlay",
        }}
      />

      {/* Border Glow (Subtle) */}
      <motion.div
        className="absolute inset-0 rounded-2xl border-2 border-opacity-0 group-hover:border-opacity-50 border-white transition-opacity duration-300 pointer-events-none"
      />
    </motion.div>
  );
};

export default PortfolioItem;