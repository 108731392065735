import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import AOS from "aos";
import "aos/dist/aos.css";
import emailjs from "emailjs-com";
import { cn } from "../utils";

function ElegantShape({
	className,
	delay = 0,
	width = 400,
	height = 100,
	rotate = 0,
	gradient = "from-white/[0.08]",
}) {
	return (
		<motion.div
			initial={{
				opacity: 0,
				y: -150,
				rotate: rotate - 15,
			}}
			animate={{
				opacity: 1,
				y: 0,
				rotate: rotate,
			}}
			transition={{
				duration: 2.4,
				delay,
				ease: [0.23, 0.86, 0.39, 0.96],
				opacity: { duration: 1.2 },
			}}
			className={cn("absolute", className)}
		>
			<motion.div
				animate={{
					y: [0, 15, 0],
				}}
				transition={{
					duration: 12,
					repeat: Number.POSITIVE_INFINITY,
					ease: "easeInOut",
				}}
				style={{
					width,
					height,
				}}
				className="relative"
			>
				<div
					className={cn(
						"absolute inset-0 rounded-full",
						"bg-gradient-to-r to-transparent",
						gradient,
						"backdrop-blur-[2px] border-2 border-white/[0.15]",
						"shadow-[0_8px_32px_0_rgba(255,255,255,0.1)]",
						"after:absolute after:inset-0 after:rounded-full",
						"after:bg-[radial-gradient(circle_at_50%_50%,rgba(255,255,255,0.2),transparent_70%)]"
					)}
				/>
			</motion.div>
		</motion.div>
	);
}

export default function ModernContactFooter() {
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		emailjs.init("sEXZK-Jh09yP7hC0P");
		AOS.init({
			duration: 1200,
			easing: "ease-in-out",
			once: true,
			delay: 200,
		});
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const form = e.target;
		const serviceId = "service_jg65yhc";
		const templateId = "template_17ofu1u";
		try {
			setLoading(true);
			const result = await emailjs.send(serviceId, templateId, {
				to_name: form.elements.name.value,
				from_email: form.elements.email.value,
				phone: form.elements.phone.value,
				message: form.elements.message.value,
			});

			if (result.status === 200) {
				alert(
					"Welcome on board! We have received your email, will reach out to you in no time"
				);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};
    
    useEffect(() => {
            AOS.init({
                duration: 1200, // Animation duration
                easing: "ease-in-out", // Animation easing
                once: true, // Whether animation should happen only once
                delay: 200, // Delay before animation starts
            });
        }, []);
    

	const fadeUpVariants = {
		hidden: { opacity: 0, y: 30 },
		visible: (i) => ({
			opacity: 1,
			y: 0,
			transition: {
				duration: 1,
				delay: 0.5 + i * 0.2,
				ease: [0.25, 0.4, 0.25, 1],
			},
		}),
	};

	const formFieldVariants = {
		hidden: { opacity: 0, x: -20 },
		visible: {
			opacity: 1,
			x: 0,
			transition: {
				duration: 0.6,
				ease: "easeOut",
			},
		},
	};

	return (
		<section id="Contact" className="relative min-h-screen flex items-center justify-center overflow-hidden bg-[#030303]">
			<div className="absolute inset-0 bg-gradient-to-br from-indigo-500/[0.05] via-transparent to-rose-500/[0.05] blur-3xl" />

			{/* Background Text */}
			<div className="absolute inset-0 flex items-center justify-center overflow-hidden pointer-events-none">
				<motion.span
					initial={{ opacity: 0, scale: 0.5 }}
					animate={{ opacity: 0.03, scale: 1 }}
					transition={{ duration: 1.5, ease: "easeOut" }}
					className="text-[15vw] text-white font-pacifico whitespace-nowrap"
				>
					Get in Touch
				</motion.span>
			</div>

			<div className="container relative z-10 mx-auto px-4 py-16">
				<div className="max-w-4xl mx-auto text-center">
					<motion.div
						custom={0}
						variants={fadeUpVariants}
						initial="hidden"
						animate="visible"
						className="inline-flex items-center gap-2 px-3 py-2 rounded-full bg-white/[0.08] mb-8 md:mb-12"
						data-aos="fade-up"
					>
						<img src="/logo_dark.png" alt="Easynet" className="w-4 h-4" />
						<span className="text-sm text-white tracking-wide">Contact Us</span>
					</motion.div>

					<motion.div
						custom={1}
						variants={fadeUpVariants}
						initial="hidden"
						animate="visible"
						className="text-center mb-12"
					>
						<h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6 tracking-tight">
							<span className="bg-clip-text text-transparent bg-gradient-to-b from-white to-white/80">
								Let's Build
							</span>
							<br />
							<span className="p-4 font-pacifico bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 via-white/90 to-rose-400">
								Something Amazing
							</span>
						</h1>
					</motion.div>

					<div className="relative z-10 bg-white/[0.02] backdrop-blur-sm rounded-2xl p-8 shadow-2xl border border-white/[0.05]">
						<form onSubmit={handleSubmit} className="space-y-6">
							<motion.div
								variants={formFieldVariants}
								initial="hidden"
								whileInView="visible"
							>
								<input
									type="text"
									name="name"
									placeholder="Your Name"
									className="w-full px-6 py-4 bg-white/[0.03] border border-white/[0.1] text-white placeholder-gray-400 rounded-xl focus:outline-none focus:ring-2 focus:ring-indigo-500/50 transition-all duration-300"
								/>
							</motion.div>
							<motion.div
								variants={formFieldVariants}
								initial="hidden"
								whileInView="visible"
								custom={1}
							>
								<input
									type="email"
									name="email"
									placeholder="Your Email"
									className="w-full px-6 py-4 bg-white/[0.03] border border-white/[0.1] text-white placeholder-gray-400 rounded-xl focus:outline-none focus:ring-2 focus:ring-indigo-500/50 transition-all duration-300"
								/>
							</motion.div>
							<motion.div
								variants={formFieldVariants}
								initial="hidden"
								whileInView="visible"
								custom={2}
							>
								<input
									type="tel"
									name="phone"
									placeholder="Your Phone"
									className="w-full px-6 py-4 bg-white/[0.03] border border-white/[0.1] text-white placeholder-gray-400 rounded-xl focus:outline-none focus:ring-2 focus:ring-indigo-500/50 transition-all duration-300"
								/>
							</motion.div>
							<motion.div
								variants={formFieldVariants}
								initial="hidden"
								whileInView="visible"
								custom={3}
							>
								<textarea
									name="message"
									placeholder="Your Message"
									rows={5}
									className="w-full px-6 py-4 bg-white/[0.03] border border-white/[0.1] text-white placeholder-gray-400 rounded-xl focus:outline-none focus:ring-2 focus:ring-indigo-500/50 transition-all duration-300"
								/>
							</motion.div>
							<motion.div
								variants={formFieldVariants}
								initial="hidden"
								whileInView="visible"
								custom={4}
								className="pt-4"
							>
								<motion.button
									type="submit"
									className="w-full bg-gradient-to-r from-indigo-500 to-rose-400 hover:from-rose-500 hover:to-indigo-500 text-white font-semibold px-8 py-4 rounded-xl shadow-lg transition-all duration-300 disabled:opacity-50"
									disabled={loading}
									whileHover={{ scale: 1.02 }}
									whileTap={{ scale: 0.98 }}
								>
									{loading ? "Sending..." : "Send Message"}
								</motion.button>
							</motion.div>
						</form>
					</div>

					<motion.div
						variants={fadeUpVariants}
						initial="hidden"
						animate="visible"
						custom={3}
						className="mt-12 text-center"
					>
						<div className="flex flex-wrap justify-center gap-8 mb-8">
							<motion.div
								className="text-gray-400 flex flex-col items-center gap-2"
								whileHover={{ scale: 1.05 }}
								transition={{ type: "spring", stiffness: 400, damping: 10 }}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-5 w-5"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									strokeWidth="2"
								>
									<path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
								</svg>
								<div>
									<span className="block font-semibold text-white">Phone</span>
									+234 (706) 9333 829
								</div>
							</motion.div>
							<motion.div
								className="text-gray-400 flex flex-col items-center gap-2"
								whileHover={{ scale: 1.05 }}
								transition={{ type: "spring", stiffness: 400, damping: 10 }}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-5 w-5"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									strokeWidth="2"
								>
									<path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
									<circle cx="12" cy="10" r="3" />
								</svg>
								<div>
									<span className="block font-semibold text-white">
										Location
									</span>
									Nigeria, United Kingdom, Benin Rep
								</div>
							</motion.div>
							<motion.div
								className="text-gray-400 flex flex-col items-center gap-2"
								whileHover={{ scale: 1.05 }}
								transition={{ type: "spring", stiffness: 400, damping: 10 }}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-5 w-5"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									strokeWidth="2"
								>
									<path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
									<polyline points="22,6 12,13 2,6" />
								</svg>
								<div>
									<span className="block font-semibold text-white">Email</span>
									sales@easynetsurveco.com
								</div>
							</motion.div>
						</div>
						<div className="border-t border-white/10 pt-8 mt-12">
							<p className="text-gray-400 mb-6">
								&copy; 2023 EASYNET TELSURVE CO. All Rights Reserved
							</p>
							<div className="flex justify-center space-x-6">
								<a
									href="#"
									className="text-gray-400 hover:text-white transition-colors"
								>
									<svg
										className="h-6 w-6"
										fill="currentColor"
										viewBox="0 0 24 24"
										aria-hidden="true"
									>
										<path
											fillRule="evenodd"
											d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
											clipRule="evenodd"
										/>
									</svg>
								</a>
								<a
									href="#"
									className="text-gray-400 hover:text-white transition-colors"
								>
									<svg
										className="h-6 w-6"
										fill="currentColor"
										viewBox="0 0 24 24"
										aria-hidden="true"
									>
										<path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
									</svg>
								</a>
								<a
									href="#"
									className="text-gray-400 hover:text-white transition-colors"
								>
									<svg
										className="h-6 w-6"
										fill="currentColor"
										viewBox="0 0 24 24"
										aria-hidden="true"
									>
										<path
											fillRule="evenodd"
											d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
											clipRule="evenodd"
										/>
									</svg>
								</a>
							</div>
						</div>
					</motion.div>
				</div>
			</div>

			<div className="absolute inset-0 overflow-hidden pointer-events-none">
				<ElegantShape
					delay={0.3}
					width={600}
					height={140}
					rotate={12}
					gradient="from-indigo-500/[0.15]"
					className="left-[-10%] md:left-[-5%] top-[15%] md:top-[20%]"
                    data-aos="fade-right" // Animate on scroll
				/>
				<ElegantShape
					delay={0.5}
					width={500}
					height={120}
					rotate={-15}
					gradient="from-rose-500/[0.15]"
					className="right-[-5%] md:right-[0%] top-[70%] md:top-[75%]"
                    data-aos="fade-left" // Animate on scroll
				/>
				<ElegantShape
					delay={0.4}
					width={300}
					height={80}
					rotate={-8}
					gradient="from-violet-500/[0.15]"
					className="left-[5%] md:left-[10%] bottom-[5%] md:bottom-[10%]"
                    data-aos="fade-up" // Animate on scroll
				/>
				<ElegantShape
					delay={0.6}
					width={200}
					height={60}
					rotate={20}
					gradient="from-amber-500/[0.15]"
					className="right-[15%] md:right-[20%] top-[10%] md:top-[15%]"
                    data-aos="fade-down" // Animate on scroll
				/>
                <ElegantShape
					delay={0.7}
					width={150}
					height={40}
					rotate={-25}
					gradient="from-cyan-500/[0.15]"
					className="left-[20%] md:left-[25%] top-[5%] md:top-[10%]"
					data-aos="zoom-in" // Animate on scroll
				/>
			</div>

			<div className="absolute inset-0 bg-gradient-to-t from-[#030303] via-transparent to-[#030303]/80 pointer-events-none" />
		</section>
	);
}
