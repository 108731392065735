"use client";

import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { CounterItem } from "./CounterItem";
import { BackgroundShapes } from "./BackgroundShapes";

const LightProjectCounter = () => {
	const controls = useAnimation();
	const [ref, inView] = useInView({
		triggerOnce: true,
		threshold: 0.1,
	});

	useEffect(() => {
		if (inView) {
			controls.start("visible");
		}
	}, [controls, inView]);

	const containerVariants = {
		hidden: { opacity: 0 },
		visible: {
			opacity: 1,
			transition: {
				staggerChildren: 0.2,
				delayChildren: 0.3,
			},
		},
	};

	const titleVariants = {
		hidden: { opacity: 0, y: -50 },
		visible: {
			opacity: 1,
			y: 0,
			transition: {
				duration: 0.8,
				ease: [0.17, 0.67, 0.83, 0.67],
			},
		},
	};

	return (
		<section className="relative py-24 overflow-hidden min-h-screen flex items-center justify-center">
			<div className="absolute inset-0 bg-gradient-to-br from-indigo-50 via-white to-rose-50" />
			<BackgroundShapes />
			<div className="container mx-auto px-4 text-center relative z-10">
				<motion.div
					ref={ref}
					variants={containerVariants}
					initial="hidden"
					animate={controls}
					className="inline-flex items-center justify-center gap-2 px-3 py-2 rounded-full bg-gradient-to-r from-indigo-500/10 to-rose-500/10 backdrop-blur-sm mb-8"
				>
					<img src="/logo.png" alt="Easynet" className="w-8 h-8" />
					<motion.h2
						variants={titleVariants}
						className="text-xl font-medium text-gray-800 items-center justify-center"
					>
						Our Statistics
					</motion.h2>
				</motion.div>
				<motion.div
					ref={ref}
					variants={containerVariants}
					initial="hidden"
					animate={controls}
					className="text-center mb-16"
				>
					<motion.h1
						variants={titleVariants}
						className="text-5xl md:text-6xl font-bold text-gray-800 leading-tight mb-8"
					>
						We Are{" "}
						<span className="font-pacifico p-4 bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 to-rose-500">
							Transforming
						</span>{" "}
						Businesses
					</motion.h1>
					<motion.p
						variants={titleVariants}
						className="text-xl text-gray-600 max-w-3xl mx-auto"
					>
						Our digital transformation and business development agency is
						driving innovation and growth across industries.
					</motion.p>
				</motion.div>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
					<CounterItem
						icon="Code"
						count="600+"
						text="Projects Completed"
						index={0}
					/>
					<CounterItem
						icon="Users"
						count="200+"
						text="Satisfied Clients"
						index={1}
					/>
					<CounterItem
						icon="Briefcase"
						count="20+"
						text="Strategic Partners"
						index={2}
					/>
					<CounterItem
						icon="Award"
						count="50+"
						text="Expert Team Members"
						index={3}
					/>
				</div>

				<motion.div variants={titleVariants} className="mt-16 text-center">
					<motion.a
						href="https://wa.me/2347069333829"
						target="_blank"
						rel="noopener noreferrer"
						className="inline-flex items-center px-8 py-4 text-lg font-semibold text-white bg-gradient-to-r from-indigo-600 to-rose-500 rounded-full shadow-lg transition-all duration-300 hover:from-rose-500 hover:to-indigo-600 hover:scale-105"
						whileHover={{ scale: 1.05 }}
						whileTap={{ scale: 0.95 }}
					>
						Start Your Project
					</motion.a>
				</motion.div>
			</div>
		</section>
	);
};

export default LightProjectCounter;
