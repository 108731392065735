import React, { useEffect } from "react";
import { motion } from "framer-motion";
import "@fontsource/pacifico";
import { cn } from "../utils";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles

function ElegantShape({
	className,
	delay = 0,
	width = 400,
	height = 100,
	rotate = 0,
	gradient = "from-white/[0.08]",
}) {
	return (
		<motion.div
			initial={{
				opacity: 0,
				y: -150,
				rotate: rotate - 15,
			}}
			animate={{
				opacity: 1,
				y: 0,
				rotate: rotate,
			}}
			transition={{
				duration: 2.4,
				delay,
				ease: [0.23, 0.86, 0.39, 0.96],
				opacity: { duration: 1.2 },
			}}
			className={cn("absolute", className)}
		>
			<motion.div
				animate={{
					y: [0, 15, 0],
				}}
				transition={{
					duration: 12,
					repeat: Infinity,
					ease: "easeInOut",
				}}
				style={{
					width,
					height,
				}}
				className="relative"
			>
				<div
					className={cn(
						"absolute inset-0 rounded-full",
						"bg-gradient-to-r to-transparent",
						gradient,
						"backdrop-blur-[2px] border-2 border-white/[0.15]",
						"shadow-[0_8px_32px_0_rgba(255,255,255,0.1)]",
						"after:absolute after:inset-0 after:rounded-full",
						"after:bg-[radial-gradient(circle_at_50%_50%,rgba(255,255,255,0.2),transparent_70%)]"
					)}
				/>
			</motion.div>
		</motion.div>
	);
}

export default function HeroGeometric({
	badge = "Easynet",
	title1 = "Elevate Your",
	title2 = "Digital Vision",
}) {
	useEffect(() => {
		AOS.init({
			duration: 1200, // Animation duration
			easing: "ease-in-out", // Animation easing
			once: true, // Whether animation should happen only once
			delay: 200, // Delay before animation starts
		});
	}, []);

	const fadeUpVariants = {
		hidden: { opacity: 0, y: 30 },
		visible: (i) => ({
			opacity: 1,
			y: 0,
			transition: {
				duration: 1,
				delay: 0.5 + i * 0.2,
				ease: [0.25, 0.4, 0.25, 1],
			},
		}),
	};

	const buttonVariants = {
		hover: {
			scale: 1.05,
			boxShadow: "0 0 25px rgba(255, 255, 255, 0.5)",
		},
		tap: {
			scale: 0.95,
		},
	};

	return (
		<div className="relative min-h-[80vh] w-full flex items-center justify-center overflow-hidden bg-[#030303]">
			<div className="absolute inset-0 bg-gradient-to-br from-indigo-500/[0.05] via-transparent to-rose-500/[0.05] blur-3xl" />

			<div className="absolute inset-0 overflow-hidden">
				<ElegantShape
					delay={0.3}
					width={600}
					height={140}
					rotate={12}
					gradient="from-indigo-500/[0.15]"
					className="left-[-10%] md:left-[-5%] top-[15%] md:top-[20%]"
					data-aos="fade-right" // Animate on scroll
				/>

				<ElegantShape
					delay={0.5}
					width={500}
					height={120}
					rotate={-15}
					gradient="from-rose-500/[0.15]"
					className="right-[-5%] md:right-[0%] top-[70%] md:top-[75%]"
					data-aos="fade-left" // Animate on scroll
				/>

				<ElegantShape
					delay={0.4}
					width={300}
					height={80}
					rotate={-8}
					gradient="from-violet-500/[0.15]"
					className="left-[5%] md:left-[10%] bottom-[5%] md:bottom-[10%]"
					data-aos="fade-up" // Animate on scroll
				/>

				<ElegantShape
					delay={0.6}
					width={200}
					height={60}
					rotate={20}
					gradient="from-amber-500/[0.15]"
					className="right-[15%] md:right-[20%] top-[10%] md:top-[15%]"
					data-aos="fade-down" // Animate on scroll
				/>

				<ElegantShape
					delay={0.7}
					width={150}
					height={40}
					rotate={-25}
					gradient="from-cyan-500/[0.15]"
					className="left-[20%] md:left-[25%] top-[5%] md:top-[10%]"
					data-aos="zoom-in" // Animate on scroll
				/>
			</div>

			<div className="relative z-10 container mx-auto px-4 md:px-6">
				<div className="max-w-3xl mx-auto text-center">
					<motion.div
						custom={0}
						variants={fadeUpVariants}
						initial="hidden"
						animate="visible"
						className="inline-flex items-center gap-2 px-3 py-2 rounded-full bg-white/[0.08] mb-8 md:mb-12"
						data-aos="fade-up"
						data-aos-delay="100"
					>
						<img src="/logo_dark.png" alt="Easynet" className="w-4 h-4" />
						<span className="text-sm text-white tracking-wide">{badge}</span>
					</motion.div>

					<motion.div
						custom={1}
						variants={fadeUpVariants}
						initial="hidden"
						animate="visible"
						data-aos="fade-up"
						data-aos-delay="200"
					>
						<h1 className="text-4xl sm:text-6xl md:text-8xl font-bold mb-6 md:mb-8 tracking-tight">
							<span className="bg-clip-text text-transparent bg-gradient-to-b from-white to-white/80">
								{title1}
							</span>
							<br />
							<span
								className={cn(
									"p-3 bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 via-white/90 to-rose-400",
									"font-pacifico"
								)}
							>
								{title2}
							</span>
						</h1>
					</motion.div>

					<motion.div
						custom={2}
						variants={fadeUpVariants}
						initial="hidden"
						animate="visible"
						data-aos="fade-up"
						data-aos-delay="300"
					>
						<p className="text-base sm:text-lg md:text-xl text-white/40 mb-8 leading-relaxed font-light tracking-wide max-w-xl mx-auto px-4">
							Crafting exceptional digital experiences through innovative design
							and cutting-edge technology.
						</p>
					</motion.div>

					<motion.div
						custom={3}
						variants={fadeUpVariants}
						initial="hidden"
						animate="visible"
						data-aos="fade-up"
						data-aos-delay="400"
					>
						<motion.a
							href="https://wa.me/2347069333829"
							target="_blank"
							rel="noopener noreferrer"
							className={cn(
								"inline-flex items-center justify-center",
								"px-8 py-4 text-lg font-semibold text-white",
								"bg-gradient-to-r from-indigo-500 to-rose-400",
								"rounded-full shadow-lg",
								"transition-all duration-300 ease-in-out",
								"hover:from-rose-500 hover:to-indigo-500",
								"focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400"
							)}
							variants={buttonVariants}
							whileHover="hover"
							whileTap="tap"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								fill="currentColor"
								className="w-6 h-6 mr-2"
							>
								<path
									fillRule="evenodd"
									d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
									clipRule="evenodd"
								/>
							</svg>
							Contact Us
						</motion.a>
					</motion.div>
				</div>
			</div>

			<div className="absolute inset-0 bg-gradient-to-t from-[#030303] via-transparent to-[#030303]/80 pointer-events-none" />
		</div>
	);
}
