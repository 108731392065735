import React from "react";
import Slider from "react-slick";
import ScrollToDown from "./ScrollToDown";
import { motion } from "framer-motion";
import "@fontsource/pacifico";
import { cn } from "../utils";
import AOS from "aos";
import "aos/dist/aos.css";

const Breadcrumb = () => {
	const settings = {
		dots: true,
		arrows: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true, // Enables auto slide show
		autoplaySpeed: 2000,
	};

	const fadeUpVariants = {
		hidden: { opacity: 0, y: 30 },
		visible: (i) => ({
			opacity: 1,
			y: 0,
			transition: {
				duration: 1,
				delay: 0.5 + i * 0.2,
				ease: [0.25, 0.4, 0.25, 1],
			},
		}),
	};

	const buttonVariants = {
		hover: {
			scale: 1.05,
			boxShadow: false
				? "0 0 25px rgba(0, 0, 0, 0.2)"
				: "0 0 25px rgba(255, 255, 255, 0.5)",
		},
		tap: {
			scale: 0.95,
		},
	};
	return (
		<section className="hero-area hero-carousel-active overflow-clip" id="home">
			<Slider {...settings}>
				<div
					className="single-hero-area hero-bg-1 bg_cover"
					data-scroll-area="true"
				>
					<div className="container">
						<div className="row">
							<div className="col-lg-7">
								<div className="hero-area-content">
									<p className="hero-para">
										Revolutionize your business with <br />
										EasyNet Telsurve Co's cutting-edge{" "}
									</p>
									<h1 className="hero-heading">
										Micro - Services & Software Developemt
									</h1>
									<p className="hero-para">
										delivering seamless integration and <br />
										superior performance for your operations.{" "}
									</p>
									<div className="hero-area-menu">
										<ul>
											<li>
												<a href="#">Microservices</a>
											</li>
											<li>
												<a href="#">Software Developemt</a>
											</li>
											<li>
												<a href="#">System Admin / DevOps</a>
											</li>
										</ul>
									</div>
									<motion.div
										custom={3}
										variants={fadeUpVariants}
										initial="hidden"
										animate="visible"
										data-aos="fade-up"
										data-aos-delay="400"
									>
										<motion.a
											href="https://wa.me/2347069333829"
											target="_blank"
											rel="noopener noreferrer"
											className={cn(
												"inline-flex items-center justify-center",
												"px-8 py-4 text-lg font-semibold text-white",
												`bg-gradient-to-r ${"from-indigo-500 to-rose-400"}`,
												"rounded-full shadow-lg",
												"transition-all duration-300 ease-in-out",
												`hover:from-rose-500 hover:to-indigo-500 ${
													false ? "text-gray-800" : "text-white"
												}`,
												"focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400"
											)}
											variants={buttonVariants}
											whileHover="hover"
											whileTap="tap"
										>
											Get Started
										</motion.a>
									</motion.div>
								</div>
							</div>
						</div>
						<ScrollToDown />
					</div>
				</div>
				<div
					className="single-hero-area hero-bg-2 bg_cover"
					data-scroll-area="true"
				>
					<div className="container">
						<div className="row">
							<div className="col-lg-7">
								<div className="hero-area-content">
									<p className="hero-para">
										{" "}
										At EasyNet Telsurve Co, we
										<br />
										leverage on the power of{" "}
									</p>
									<h1 className="hero-heading">AI Engineering & Modelling</h1>
									<p className="hero-para">
										{" "}
										to ensure our clients get the best out of their investments
										startups.{" "}
									</p>
									<div className="hero-area-menu">
										<ul>
											<li>
												<a href="#">Microservices</a>
											</li>
											<li>
												<a href="#">Software Developemt</a>
											</li>
											<li>
												<a href="#">System Admin / DevOps</a>
											</li>
										</ul>
									</div>
									<motion.div
										custom={3}
										variants={fadeUpVariants}
										initial="hidden"
										animate="visible"
										data-aos="fade-up"
										data-aos-delay="400"
									>
										<motion.a
											href="https://wa.me/2347069333829"
											target="_blank"
											rel="noopener noreferrer"
											className={cn(
												"inline-flex items-center justify-center",
												"px-8 py-4 text-lg font-semibold text-white",
												`bg-gradient-to-r ${"from-indigo-500 to-rose-400"}`,
												"rounded-full shadow-lg",
												"transition-all duration-300 ease-in-out",
												`hover:from-rose-500 hover:to-indigo-500 ${
													false ? "text-gray-800" : "text-white"
												}`,
												"focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400"
											)}
											variants={buttonVariants}
											whileHover="hover"
											whileTap="tap"
										>
											Get Started
										</motion.a>
									</motion.div>
								</div>
							</div>
						</div>
					</div>
					<ScrollToDown />
				</div>
				<div
					className="single-hero-area hero-bg-3 bg_cover"
					data-scroll-area="true"
				>
					<div className="container">
						<div className="row">
							<div className="col-lg-7">
								<div className="hero-area-content">
									<p className="hero-para">
										Unlock the full potential of your infrastructure with <br />
										EasyNet Telsurve Co's robust System Admin and
									</p>
									<h1 className="hero-heading">DevOps strategies,</h1>
									<p className="hero-para">
										guaranteeing optimal security and <br />
										streamlined management for your business.
									</p>
									<div className="hero-area-menu">
										<ul>
											<li>
												<a href="#">Microservices</a>
											</li>
											<li>
												<a href="#">Software Developemt</a>
											</li>
											<li>
												<a href="#">System Admin / DevOps</a>
											</li>
										</ul>
									</div>
									<motion.div
										custom={3}
										variants={fadeUpVariants}
										initial="hidden"
										animate="visible"
										data-aos="fade-up"
										data-aos-delay="400"
									>
										<motion.a
											href="https://wa.me/2347069333829"
											target="_blank"
											rel="noopener noreferrer"
											className={cn(
												"inline-flex items-center justify-center",
												"px-8 py-4 text-lg font-semibold text-white",
												`bg-gradient-to-r ${"from-indigo-500 to-rose-400"}`,
												"rounded-full shadow-lg",
												"transition-all duration-300 ease-in-out",
												`hover:from-rose-500 hover:to-indigo-500 ${
													false ? "text-gray-800" : "text-white"
												}`,
												"focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400"
											)}
											variants={buttonVariants}
											whileHover="hover"
											whileTap="tap"
										>
											Get Started
										</motion.a>
									</motion.div>
								</div>
							</div>
						</div>
					</div>
					<ScrollToDown />
				</div>
			</Slider>
		</section>
	);
};

export default Breadcrumb;
