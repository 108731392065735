"use client";

import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import * as Icon from "lucide-react";

export const CounterItem = ({ icon, count, text, index }) => {
	const controls = useAnimation();
	const [ref, inView] = useInView({
		triggerOnce: true,
		threshold: 0.1,
	});

	useEffect(() => {
		if (inView) {
			controls.start("visible");
		}
	}, [controls, inView]);

	const itemVariants = {
		hidden: { opacity: 0, y: 50 },
		visible: {
			opacity: 1,
			y: 0,
			transition: {
				duration: 0.8,
				delay: index * 0.2,
				ease: [0.17, 0.67, 0.83, 0.67],
			},
		},
	};

	const iconVariants = {
		hidden: { scale: 0, rotate: -180 },
		visible: {
			scale: 1,
			rotate: 0,
			transition: {
				type: "spring",
				stiffness: 260,
				damping: 20,
				delay: index * 0.2 + 0.3,
			},
		},
	};

	const numberVariants = {
		hidden: { opacity: 0 },
		visible: {
			opacity: 1,
			transition: {
				duration: 0.5,
				delay: index * 0.2 + 0.6,
			},
		},
	};

	const IconComponent = Icon[icon] || Icon["HelpCircle"];

	return (
		<motion.div
			ref={ref}
			variants={itemVariants}
			initial="hidden"
			animate={controls}
			whileHover={{
				scale: 1.1,
				rotateX: 5,
				rotateY: 5,
				boxShadow: "0px 10px 30px rgba(136, 58, 225, 0.3)",
			}}
			transition={{ type: "spring", stiffness: 200, damping: 10 }}
			className="relative flex flex-col items-center p-8 bg-white/10 backdrop-blur-lg rounded-3xl shadow-2xl transition-transform duration-300 border border-white/20"
		>
			<motion.div
				className="text-5xl mb-4 text-indigo-600 drop-shadow-glow"
				variants={iconVariants}
			>
				<IconComponent size={48} />
			</motion.div>
			<motion.h2
				className="text-4xl font-bold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 to-rose-500"
				variants={numberVariants}
			>
				{count}
			</motion.h2>
			<p className="text-gray-600 text-center font-medium">{text}</p>
		</motion.div>
	);
};
